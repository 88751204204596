<template>
<div class="page">
  <h5 class="text-muted">Vertical center using auto-margins..</h5>
  <!--vertical align on parent using my-auto-->
  <div class="d-flex h-100">
      <div class="col-sm-12 my-auto">
          I am Groot.
      </div>
  </div>
  <h5 class="text-muted">Vertical &amp; horizontal center using auto-margins..</h5>
  <!--vertical align on parent using my-auto, horizontal align on self mx-auto-->
  <div class="d-flex h-100">
      <div class="col-sm-12 my-auto">
          <div class="card card-block w-25 mx-auto">I am Groot.</div>
      </div>
  </div>
  <div class="note">
      Various vertical align examples with Bootstrap 4
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>

.page {
  height: 80vh;
}

html, body {
    height: 100%;
}

/* CSS only for examples not required for centering */
.container {
    height: 100%;
}

.note {
    position: absolute;
    z-index: 10;
    right: 0;
    top: 0;
    padding: 5px;
    background: #eee;
    max-width: 360px;
    border: 1px dotted #bbb;
}

/* show border around full height container */
.h-100 {
    border: 1px dotted #cc2222;
}

</style>